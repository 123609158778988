import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { PdfViewer } from './PdfViewer';
import { ScrollContent, t, stripQueryParams, ItemButton } from '@code-yellow/spider';

const StyledImage = styled.img`
    max-width: 100%;
`
export type FileViewerProps = {
    previewUrl: string,
    downloadUrl: string,
    fileType: string,
    id: string,
}

@observer
export class FileViewer extends Component<FileViewerProps> {
    _renderEmail() {
        const { downloadUrl, id, previewUrl } = this.props;

        // TODO: add an email preview here
        // should also allow downloading
        return <ScrollContent style={{ 'padding': '10px' }} data-test-email-viewer-url={previewUrl}>
            {t('administration:dossier.edit.document.email')}: &nbsp;
            <ItemButton
                icon="download"
                data-test-download-file={id}
                as="a"
                href={downloadUrl}
                label={t('administration:dossier.edit.document.downloadButton')}
            />
        </ScrollContent>
    }

    _renderImage() {
        const { previewUrl } = this.props;
        return <StyledImage src={previewUrl} data-test-img-viewer-url={previewUrl}/>
    }

    _renderPdf() {
        const { previewUrl } = this.props;
        return <PdfViewer
            data-test-pdf-viewer-url={previewUrl}
            url={stripQueryParams(previewUrl)}
        />
    }

    render() {
        const { fileType } = this.props

        if (fileType === 'eml') {
            // email files cannot be rendered using an iframe
            return this._renderEmail()
        } else if (['jpeg', 'jpg', 'png'].includes(fileType?.toLowerCase())) {
            // handle images, PdfViewer does not work for IE8/Chrome
            return this._renderImage()
        }

        // this can handle: pdf and images, however images not very well in IE8
        return this._renderPdf()
    }
}
