import React from 'react';
import {
    ClearButton, TargetBase, TargetMultiPick as BaseTargetMultiPick,
    TargetNumberInput, TargetWeekPicker as BaseTargetWeekPicker,
    TargetMonthPicker as BaseTargetMonthPicker, TargetMoneyInput as BaseTargetMoneyInput,
    TargetSelect as BaseTargetSelect, t
} from '@code-yellow/spider';
import { computed, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { FormGroup } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { diffChars } from 'diff';
import { escapeRegExp } from 'lodash';


@observer
export class TargetMultiPick extends BaseTargetMultiPick {
    @computed get valueStore() {
        const { store, remote, searchKey } = this.props;

        if (!remote) {
            return undefined;
        }

        if (this.valueStoreReaction) {
            this.valueStoreReaction();
        }

        if (this.type === 'model') {
            return this.getValueBase();
        } else {
            const Store = store.constructor;
            const valueStore = new Store();
            this.valueStoreReaction = reaction(
                () => this.value,
                (value) => {
                    if (value.length > 0) {
                        // This by default does not use search key and when not using id/int as a value it will return 418 from backend. Should be fixed in spider
                        valueStore.params[searchKey ?? '.id:in'] = value.join(',');
                        valueStore.fetch();
                    } else {
                        valueStore.clear();
                    }
                },
                { fireImmediately: true },
            );
            return valueStore;
        }
    }

}

export const TargetNumberRangeFormGroup = styled(FormGroup)`
    .field {
        width: 50%;
        input {
            width: 100%;
        }
    }
`

// It is not 100% valid target component
// Do not use it for setting values in the models as it lacks the proper implementation for that(and we dont support storing number ranges in the db anyway)
// Its main purpose is to be used as a filter on the overview, nothing more. Because of this it should not be ported to the spider
// Its not using :range binder qualifier, because it does not support ranges with only one value provided. Eg. "1,10" is a valid range, but "1" or "1," is not.
@observer
export class TargetNumberRangeFilter extends TargetBase {
    static defaultProps = {
        ...TargetNumberInput.defaultProps,
    };

    renderContent() {
        const { name } = this.props;

        return (
            <TargetNumberRangeFormGroup>
                <TargetNumberInput
                    {...this.props}
                    placeholder={t('target.range.from')}
                    noLabel
                    name={`${name}:gte`}
                />
                <TargetNumberInput
                    {...this.props}
                    placeholder={t('target.range.to')}
                    noLabel
                    name={`${name}:lte`}
                />
            </TargetNumberRangeFormGroup>
        )
    }
}

@observer
export class TargetMoneyRangeFilter extends TargetNumberRangeFilter {
    static defaultProps = {
        ...TargetNumberInput.defaultProps,
        allowDecimal: true,
        decimalLimit: 2,
        includeThousandsSeparator: true,
        prefix: '€',
    };
}

export class TargetWeekPicker extends BaseTargetWeekPicker {
    static propTypes = {
        ...BaseTargetWeekPicker.propTypes,
        onClear: PropTypes.func,
        clearable: PropTypes.bool
    };

    getValueBase(name) {
        const { yearName, weekName } = this.props;

        if (name || !yearName || !weekName) {
            return super.getValueBase(name);
        }

        const year = this.getValueBase(yearName);
        const week = this.getValueBase(weekName);

        if (year && week) {
            // Make sure week is left padded with 0 for single digit
            return `${year}-W${week.toString().padStart(2, '0')}`;
        }

        return this.type === 'model' ? null : undefined;
    }

    toModel(value) {
        if (!value) {
            return null;
        }
        const { year, week } = value;
        return `${year}-W${week.toString().padStart(2, '0')}`;
    }

    fromModel(value) {
        if (!value) {
            return null;
        }
        const [year, week] = value.split('-W');
        return { year: parseInt(year), week: parseInt(week) };
    }

    toStore(value) {
        if (!value) {
            return undefined;
        }
        const { year, week } = value;
        return `${year}-W${week.toString().padStart(2, '0')}`;
    }

    fromStore(value) {
        if (!value) {
            return null;
        }
        const [year, week] = value.split('-W');
        return { year: parseInt(year), week: parseInt(week) };
    }

    clear = () =>{
        if(this.props.onClear){
            return this.props.onClear()
        }
        this.onChange(null)
    }

    renderContent(props) {
        const { clearable } = this.props;
        return (
            <React.Fragment>
                {super.renderContent(props)}
                {this.value && clearable ? <ClearButton {...props} name='delete' onClick={this.clear}/> : null}
            </React.Fragment>
        );
    }
}

export class TargetMonthPicker extends BaseTargetMonthPicker {
    static propTypes = {
        ...BaseTargetMonthPicker.propTypes,
        onClear: PropTypes.func,
        clearable: PropTypes.bool
    };

    clear = () =>{
        if(this.props.onClear){
            return this.props.onClear()
        }
        this.onChange(null)
    }

    renderContent(props) {
        const { clearable } = this.props;
        return (
            <React.Fragment>
                {super.renderContent(props)}
                {this.value && clearable ? <ClearButton {...props} name='delete' onClick={this.clear}/> : null}
            </React.Fragment>
        );
    }
}
@observer
export class TargetSelect extends BaseTargetSelect {
    @computed get valueStore() {
        const { store, remote, searchKey } = this.props;

        if (!remote) {
            return undefined;
        }

        if (this.valueStoreReaction) {
            this.valueStoreReaction();
        }

        const Store = store.constructor;
        const Model = store.Model;
        const base = this.getValueBase();

        if (base instanceof Store) {
            return base
        } else if (base instanceof Model) {
            const res = new Store({ relations: store.__activeRelations });
            setTimeout(() => res.add(base.toJS()));
            return res;
        } else {
            const valueStore = new Store({ relations: store.__activeRelations });
            this.valueStoreReaction = reaction(
                () => this.value,
                (value) => {
                    if (typeof value === 'number') {
                        valueStore.clear();
                        const model = new Model(
                            { id: value },
                            { relations: valueStore.__activeRelations },
                        );
                        model.fetch().then(() => valueStore.add(model.toJS()));
                    } else if (Array.isArray(value)) {
                        if (value.length > 0) {
                            // This by default does not use search key and when not using id/int as a value it will return 418 from backend. Should be fixed in spider
                            valueStore.params[searchKey ?? '.id:in'] = value.join(',');
                            valueStore.fetch();
                        } else {
                            valueStore.clear();
                        }
                    } else {
                        valueStore.clear();
                    }
                },
                { fireImmediately: true },
            );
            return valueStore;
        }

    }
}

@observer
export class TargetMoneyInput extends React.Component {
    static propTypes = {
        ...BaseTargetMoneyInput.propTypes,
        alternativeDecimalSymbol: PropTypes.string,
        autoSelectEmptyValue: PropTypes.bool,
        treatNullAsZero: PropTypes.bool,
    };

    @observable componentRef = React.createRef();
    @observable inputRef = React.createRef();
    @observable lastValue = '';

    componentDidMount() {
        if (this.componentRef.current) {
            // An ugly hack to hook directly into the renderInput method of the component to add additional event listeners
            const renderInput = this.componentRef.current?.renderInput;
            this.componentRef.current.renderInput = (ref, { defaultValue, children, ...props }) => {

                const onChange = (evt) => this.onChange(evt, props);
                const onFocus = (evt) => this.onFocus(evt, props);
                const onBlur = (evt) => this.onBlur(evt, props);

                return renderInput(ref, { defaultValue, children, ...props, onChange, onFocus, onBlur });
            }
        }
    }

    replaceAlternativeDecimalSymbolWithMainDecimalSymbol(oldStr, newStr) {
        const { alternativeDecimalSymbol = '.', decimalSymbol = ',' } = this.props;
        const changes = diffChars(oldStr, newStr);

        let result = '';
        // eslint-disable-next-line no-unused-vars
        for (const change of changes) {
            if (change.added) {
                // Replace alternative decimal symbols with main decimal symbols in added sections
                result += change.value.replace(new RegExp(escapeRegExp(alternativeDecimalSymbol), 'g'), decimalSymbol);
            } else if (!change.removed) {
                // Keep unchanged sections as they are
                result += change.value;
            }
            // Ignoring removed sections as they are not in the new string
        }

        return result;
    }

    onChange = (evt, props = {}) => {
        // this.props -> props of TargetMoneyInput
        // props -> props of MaskedInput

        const { alternativeDecimalSymbol = ',' } = this.props;
        const { onChange } = props;

        const currentValue = evt.target.value;

        if (alternativeDecimalSymbol) {
            // Replace alternative decimal symbols with main decimal symbols
            // But only if the alternative decimal symbol was added, because there can be alternative decimal symbols in the value that were not added by the user(eg. a thousand separator)
            evt.target.value = this.replaceAlternativeDecimalSymbolWithMainDecimalSymbol(this.lastValue, currentValue);
        }

        // Run original onChange so it will apply the mask
        onChange?.(evt);

        // Save the last value after mask is applied so it can be use to comparison in next onChange call
        this.lastValue = evt.target.value;
    }

    onFocus = (evt, props = {}) => {
        // this.props -> props of TargetMoneyInput
        // props -> props of MaskedInput

        const { autoSelectEmptyValue = true } = this.props;
        const { onFocus } = props;
        const element = evt.target;
        const component = this.componentRef.current;

        // Focus the input if the value is 0(not set)
        if(autoSelectEmptyValue && component && element) {
            const value = component.toTarget(component.localValue);

            if (value === 0) {
                element.setSelectionRange(0, element.value.length);
            }
        }

        onFocus?.(evt);
    }

    onBlur = (evt, props = {}) => {
        // this.props -> props of TargetMoneyInput
        // props -> props of MaskedInput

        const { treatNullAsZero = true } = this.props;
        const { onBlur } = props;

        const component = this.componentRef.current;
        if (component) {
            const value = component.toTarget(component.localValue);

            // We have to explicilty check for null, because 0 is a valid value
            if(value == null && treatNullAsZero) {
                component.setValue(0);
            }
        }

        onBlur?.(evt);
    }

    render() {
        return (
            <BaseTargetMoneyInput
                {...this.props}
                ref={(node) => {
                    let domNode = ReactDOM.findDOMNode(node);
                    if (domNode) {
                        domNode = domNode.getElementsByTagName('input')[0];
                    }
                    // Sets the ref to the react component
                    this.componentRef.current = node;
                    // Sets the ref to the input element
                    this.inputRef.current = domNode;
                }}
            />
        );
    }
}
