import { Model, showSaveNotification, Store } from '@code-yellow/spider';
import { computed, observable } from 'mobx';
import { DossierStore } from 'react-logistics-administration/src/store/Dossier';
import { InvoiceStore } from 'react-logistics-finance/src/store/Invoice';
import { SelfBillItemStore } from 'react-logistics-finance/src/store/SelfBillItem';

export class SelfBill extends Model {
    static backendResourceName = 'self_bill';
    static omitFields = ['dossiersCount', 'invoices'];

    @observable id = null;

    @observable period = '';
    @observable reference = '';
    @observable remarks = '';

    @observable items = this.relation(SelfBillItemStore);
    @observable dossiers = this.relation(DossierStore);
    @observable invoices = this.relation(InvoiceStore);
    // @observable customer = this.relation(Customer);

    @observable dossiersCount = 0;

    unlink = () => {
        return this.api.post(`/self_bill/${this.id}/unlink/`).then(showSaveNotification);
    }

    @computed get totalAmount () {
        return this.items.models.reduce((total: number, item) => total + item.amount, 0)
    }
}

export class SelfBillStore extends Store<SelfBill> {
    Model = SelfBill;
    static backendResourceName = 'self_bill';
}
