import { observable, computed, action } from 'mobx';
import { camelCase } from 'lodash';

import { Model, Store, Casts, t } from '@code-yellow/spider';

import { Location } from 'react-logistics-masterdata/src';
import { DateTime } from 'luxon';

import ActivityType, { DRIVER_CHECK_IN, DRIVER_CHECK_OUT, TRAILER_PICK_UP, TRUCK_PICK_UP } from './enums/ActivityType';
import { ActivityStatus } from './enums/ActivityStatus';
import { Trip } from './Trip';

import { CustomerGroup } from 'react-core-administration/src/store/CustomerGroup';
import { DriverActivitySyncStatus } from 'react-logistics-driverapp/src/store/DriverActivitySyncStatus';
import { AssetAllocation, AssetAllocationStore } from 'react-logistics-planning/src/store/AssetAllocation';
import AssetType from 'react-logistics-masterdata/src/store/enums/AssetType';

import { TIME_FORMAT, TIME_FORMAT_LONG_WEEKDAY, zipCodeNumeric } from 'react-logistics-administration/src/helpers';
import { ASSET_ALLOCATION_ROLE } from 'react-logistics-planning/src/store/enums/AssetAllocationRole';
import { ActivityActionStore } from './ActivityAction';
import { MapMarker } from 'react-logistics-planning/src/component/MapComponent';

export function getStatusAt(status) {
    return camelCase(`status ${status} at`);
}

export function getDefaultActivityParams(previousActivity?: Activity) {
    return {
        orderedArrivalDatetimeFrom: previousActivity?.orderedArrivalDatetimeFrom ? previousActivity.orderedArrivalDatetimeFrom.startOf('day').plus({ hours: 8 }) :  DateTime.now().startOf('day').plus({ hours: 8 }),
        orderedArrivalDatetimeUntil: previousActivity?.orderedArrivalDatetimeUntil ? previousActivity.orderedArrivalDatetimeUntil.startOf('day').plus({ hours: 16 }) :  DateTime.now().startOf('day').plus({ hours: 16 }),
    }
}

export class Activity extends Model {
    static backendResourceName = 'activity';
    static omitFields = ['invoiced', 'virtualPath', 'originalRoute', 'drivenRoute', 'distanceTo', 'priorActivity'];

    @observable id = null;

    @observable status = ActivityStatus.NEW;
    @observable subStatus = null;
    @observable type = ActivityType.LOAD;

    @observable description = null;
    @observable instructions = null;
    @observable remarks = null;
    @observable companyName = '';

    @observable asap = false;
    @observable orderedArrivalDatetimeFrom: DateTime | null = DateTime.now();
    @observable orderedArrivalDatetimeUntil: DateTime | null = DateTime.now();

    @observable ordering = 0;

    @observable createdAt = null;
    @observable updatedAt = null;
    @observable deleted = false;

    @observable priority = false;
    @observable swapped = false;
    @observable distanceTo: number | null = null;

    @observable statusFinishedAt: DateTime | null = null;
    @observable statusFinalizedAt: DateTime | null = null;
    @observable statusDrivingAt: DateTime | null = null;

    // Planned vs driven km
    @observable startKm: number | null = null;
    @observable endKm: number | null = null;
    @observable kmDeviationRemarks = '';



    @observable virtualPath = null;

    @observable priorActivity = this.relation(Activity);
    @observable location = this.relation(Location);
    @observable trip = this.relation(Trip);
    @observable allocatedCustomerGroup = this.relation(CustomerGroup);

    @observable activitySyncStatus = this.relation(DriverActivitySyncStatus);
    // This breaks modules hierarchy and created circular dependency between modules
    @observable allocations = this.relation(AssetAllocationStore);

    // Fetched route information not part of the model
    @observable originalRoute;
    @observable drivenRoute;

    @observable activityActions = this.relation(ActivityActionStore);

    @computed
    get drivenKm() {
        if (this.startKm !== null && this.endKm !== null) {
            return this.endKm - this.startKm;
        }
        return 0;
    }

    @computed
    get kmDeviation() {
        if (this.drivenKm != null && this.distanceTo != null) {
            return this.drivenKm - this.distanceTo;
        }

        return null;
    }

    @computed
    get kmDeviationPercent() {
        if (this.drivenKm != null && this.distanceTo != null) {
            if (this.kmDeviation !== 0) {
                return (((this.drivenKm / this.distanceTo) - 1) * 100);
            }
            return 0;
        }
        return null
    }

    async recalculateRoutes() {
        this.originalRoute = await this.getOriginalRoute();
        this.drivenRoute = await this.getDrivenRoute();
    }

    casts() {
        return {
            orderedArrivalDatetimeFrom: Casts.datetime,
            orderedArrivalDatetimeUntil: Casts.datetime,
            statusFinishedAt: Casts.datetime,
            statusFinalizedAt: Casts.datetime,
            statusDrivingAt: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    displayExecutionHeader() {
        let result = this.typeToActionString();

        if (this.location?.id != null && this.location?.city !== '') {
            result += ` in ${this.location?.city}`
        }

        return result;
    }

    typeToString(lang?: string): string {
        switch (this.type) {
            case ActivityType.LOAD:
                return t('administration:activity.types.load', { lng: lang });
            case ActivityType.UNLOAD:
                return t('administration:activity.types.unload', { lng: lang });
            case ActivityType.CUSTOMER_ACTIVITY:
                return t('administration:activity.types.customerActivity', { lng: lang });
            case ActivityType.TRUCK_PICK_UP:
                return t('administration:activity.types.truckPickUp', { lng: lang });
            case ActivityType.TRUCK_DROP:
                return t('administration:activity.types.truckDrop', { lng: lang });
            case ActivityType.TRUCK_SOLO:
                return t('administration:activity.types.truckSolo', { lng: lang });
            case ActivityType.TRAILER_PICK_UP:
                return t('administration:activity.types.trailerPickUp', { lng: lang });
            case ActivityType.TRAILER_DROP:
                return t('administration:activity.types.trailerDrop', { lng: lang });
            case ActivityType.CUSTOM_ACTIVITY:
                return t('administration:activity.types.customActivity', { lng: lang });
            case ActivityType.CUSTOMER_GROUP_ALLOCATION:
                return t('administration:activity.types.customerGroupAllocation', { lng: lang });
            case ActivityType.DRIVER_CHANGE:
                return t('administration:activity.types.driverChange', { lng: lang });
            case ActivityType.TRUCK_REFUEL:
                return t('administration:activity.types.truckRefuel', { lng: lang });

            // DEPRECATED
            case DRIVER_CHECK_IN:
                return t('administration:activity.types.driverCheckIn', { lng: lang });
            case DRIVER_CHECK_OUT:
                return t('administration:activity.types.driverCheckOut', { lng: lang });

            default:
                return '';
        }
    }

    typeToActionString(): string {
        switch (this.type) {
            case ActivityType.LOAD:
                return t('administration:activity.types.actions.load');
            case ActivityType.UNLOAD:
                return t('administration:activity.types.actions.unload');
            case ActivityType.CUSTOMER_ACTIVITY:
                return t('administration:activity.types.actions.customerActivity');
            case ActivityType.TRUCK_PICK_UP:
                return t('administration:activity.types.actions.truckPickUp');
            case ActivityType.TRUCK_DROP:
                return t('administration:activity.types.actions.truckDrop');
            case ActivityType.TRUCK_SOLO:
                return t('administration:activity.types.actions.truckSolo');
            case ActivityType.TRAILER_PICK_UP:
                return t('administration:activity.types.actions.trailerPickUp');
            case ActivityType.TRAILER_DROP:
                return t('administration:activity.types.actions.trailerDrop');
            case ActivityType.CUSTOM_ACTIVITY:
                return t('administration:activity.types.actions.customActivity');
            case ActivityType.CUSTOMER_GROUP_ALLOCATION:
                return t('administration:activity.types.actions.customerGroupAllocation');
            case ActivityType.DRIVER_CHANGE:
                return t('administration:activity.types.actions.driverChange');

            // DEPRECATED
            case DRIVER_CHECK_IN:
                return t('administration:activity.types.actions.driverCheckIn');
            case DRIVER_CHECK_OUT:
                return t('administration:activity.types.actions.driverCheckOut');

            default:
                return ''
        }
    }

    getInstructions(): string {
        // Set this variable to a valid i18n-registered language to force the translations to that
        // anguage. Set variable to `undefined` for translations based on user settings.
        const lang = 'en';

        const dates = [this.orderedArrivalDatetimeFrom?.toFormat(TIME_FORMAT_LONG_WEEKDAY, { locale: lang }), this.orderedArrivalDatetimeUntil?.toFormat(TIME_FORMAT, { locale: lang })].filter(x => !!x);

        const result = (
            `\n${this.typeToString(lang)} ${this.asap ? t('administration:activity.message.asap', { lng: lang }) : dates.join(` ${t('administration:activity.message.dateSeparator', { lng: lang })} `)}` +
            (this.type === ActivityType.TRAILER_PICK_UP ? `\n${t('administration:activity.message.trailer.label', { lng: lang })}: ${(this.allocatedTrailer?.licensePlate)}` : '') +
            `\n${this.companyName}\n` +
            `${this.location?.localAddressString ?? ''}\n` +
            `${this.location?.country ? this.location?.country + ' ' : ''}${this.location?.zipCode ? zipCodeNumeric(this.location?.zipCode) + ' ' : ''}${this.location?.city ?? ''}\n` +
            `${t('administration:activity.message.coordinates.label', { lng: lang })}: ${this.location?.coordinates ?? ''}` +
            (this.instructions ? `\n${t('administration:activity.field.instructions.label', { lng: lang })}: ${(this.instructions)}` : '')
        );
        return result;
    }

    static computeDispatcherStatus(status: string | null): string | null {
        if (status) {
            switch (status) {
                case 'draft':
                    return 'new';
                case 'eta':
                    return 'driving';
                case 'arrived':
                case 'started':
                    return 'working';
                default:
                    return status;
            }
        }

        return status;
    }

    static isSameStatusAsDriver(activity: Activity | null, activitySyncStatus: DriverActivitySyncStatus | null) {
        if (activitySyncStatus) {
            const dispatcherStatus = Activity.computeDispatcherStatus(activitySyncStatus.driverStatus);

            if (!dispatcherStatus) {
                return true;
            }

            if (activity?.status === ActivityStatus.EXECUTING) {
                return activity?.subStatus?.toLowerCase() === dispatcherStatus?.toLowerCase();
            }

            return activity?.status?.toLowerCase() === dispatcherStatus?.toLowerCase();
        }

        return true;
    }

    @computed
    get canBeCanceled() {
        // T48988 - allow to cancel activity in any status
        // const notAllowedStatuses = [ActivityStatus.FINISHED, ActivityStatus.FINALIZED]
        // return this.subStatus !== ActivitySubstatus.WORKING && !notAllowedStatuses.includes(this.status);
        return true;
    }

    @computed
    get canBeDeleted() {
        const allowedStatuses = [ActivityStatus.NEW, ActivityStatus.PLANNED, ActivityStatus.CANCELED]
        return allowedStatuses.includes(this.status);
    }

    @computed get marker(): null | MapMarker {
        if (!this.location?.point?.lat) {
            return null
        }
        return {
            position: [this.location.point.lat, this.location.point.lng],
            hint: `${this.type.toUpperCase()}: ${this.location.toString()}`,
        }
    }

    _setVirtualPath = (doc) => {
        if (this?.trip?.dossier) {
            doc.setVirtualPathMultipleObject([this.trip.dossier, this]);
        } else {
            doc.setVirtualPath(this);
        }
    }

    @computed get virtualPathRegex() {
        return `^${this.virtualPath}.*$`
    }

    @computed get isFirstOfTrip() {
        return this.ordering === 0;
    }

    sendPlanToDriver() {
        return this.api.post(`/activity/${this.id}/send_plan_to_driver/`);
    }

    getOriginalRoute() {
        return this.api.get(`/activity/${this.id}/get_original_route/`);
    }

    getDrivenRoute() {
        return this.api.get(`/activity/${this.id}/get_driven_route/`);
    }

    @action
    changeAssetAllocation = (type, newAsset: Driver | Truck | Trailer, role: ASSET_ALLOCATION_ROLE | null = null) => {
        // remove previous allocation
        const previousAllocation = this.getAssetAllocation(type, role)
        this.allocations.remove(previousAllocation)

        if (newAsset != null) {
            // create new allocation
            const newAllocation = new AssetAllocation(
                {
                activity: { id: this.id },
                asset: {
                    type: type,
                    id: newAsset.id,
                    },
                role: role
            }, {
                    relations: [
                    'activity',
                    'asset.trailer',
                    'asset.driver',
                    'asset.truck'
                ]
            }
            )
            newAllocation.asset[type] = newAsset;
            this.allocations.add(newAllocation.toJS());
        }
    }

    initializeFieldsFromTruck = (asset) => {
        this.setInput('trip', new Trip(asset.currentActivity?.trip.toJS(), { relations: this.trip.__activeRelations }));
    }

    @computed
    get allocatedTrailer() {
        return this.trailerAllocation?.asset.trailer;
    }

    @computed
    get hasUnallocatedTrailerPickUp() {
        return this.type === TRAILER_PICK_UP && !this.allocatedTrailer;
    }

    @computed
    get hasUnallocatedTruckPickUp() {
        return this.type === TRUCK_PICK_UP && !this.allocatedTruck;
    }

    @computed
    get trailerAllocation() {
        return this.getAssetAllocation(AssetType.TRAILER);
    }

    getAssetAllocation(type, role: ASSET_ALLOCATION_ROLE | null = null) {
        return this.allocations?.models.filter((allocation) => allocation.asset.type === type && allocation.role === role)[0];
    }

    @computed
    get allocatedTruck() {
        return this.truckAllocation?.asset.truck;
    }

    @computed
    get allocatedDriver() {
        return this.driverAllocation?.asset.driver;
    }

    @computed
    get allocatedSecondDriver() {
        return this.secondDriverAllocation?.asset.driver;
    }

    @computed
    get driverAllocation() {
        return this.getAssetAllocation(AssetType.DRIVER);
    }

    @computed
    get secondDriverAllocation() {
        return this.getAssetAllocation(AssetType.DRIVER, ASSET_ALLOCATION_ROLE.SECOND_DRIVER);
    }

    @computed
    get truckAllocation() {
        return this.getAssetAllocation(AssetType.TRUCK);
    }

    @computed
    get truck() {
        return this.truckAllocation?.asset?.truck;
    }

    @computed
    get trailer() {
        return this.trailerAllocation?.asset?.trailer;
    }

    @computed
    get driver() {
        return this.driverAllocation?.asset?.driver;
    }

    @computed
    get secondDriver() {
        return this.secondDriverAllocation?.asset?.driver;
    }

    @computed
    get hasDriverActions() {
        return this.activityActions?.length > 0;
    }
}

export class ActivityStore extends Store<Activity> {
    Model = Activity;
    static backendResourceName = 'activity';
}

export class OrderedActivityStore extends ActivityStore {
    comparator = 'ordering';
}

