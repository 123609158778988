import { showSaveNotification, showErrorNotification, Model, Store, t } from '@code-yellow/spider';
import { computed, observable, action } from 'mobx';
import { Entity } from './Entity';
import { CustomerGroup } from './CustomerGroup';
import { ContractStore } from 'react-logistics-finance/src/store/Contract';
import InvoiceLanguage from 'react-logistics-finance/src/store/enums/InvoiceLanguage';
import { parseBackendErrorCodes, parseBackendErrorMessages } from 'helpers';
import { User } from 'store/User';
import { ContactStore } from 'react-logistics-administration/src';

export class Customer extends Model {
    static backendResourceName = 'customer';

    @observable id: number | null = null;
    @observable name = '';
    @observable additionalNameField = '';
    @observable internalNotes = '';
    @observable reference = '';
    @observable phone = '';
    @observable emailAddress = ''
    @observable chamberOfCommerce = '';
    @observable iban = '';
    @observable bic = '';
    @observable emailFromName = '';
    @observable emailFromAddress = '';
    @observable emailFromNameDocumentsInvoices = '';
    @observable emailFromAddressDocumentsInvoices = '';
    @observable paymentTermCreditor = 60;
    @observable paymentTermDebtor = 60;
    @observable creditLimitAmount = 1000000; // in cents
    @observable creditLimitExpiryDate = null;
    @observable visitingAddress = '';
    @observable visitingNumber = '';
    @observable visitingZipCode = '';
    @observable visitingCity = '';
    @observable visitingCountry = '';

    @observable invoicingAddress = '';
    @observable invoicingNumber = '';
    @observable invoicingZipCode = '';
    @observable invoicingCity = '';
    @observable invoicingCountry = '';
    @observable contactType = null;
    @observable emailAddressUpdate ='';
    @observable customerNumber = '';
    @observable vatNumber = '';
    @observable invoiceLanguage: InvoiceLanguage = InvoiceLanguage.ENGLISH;
    @observable kmToleranceThreshold = null;

    @observable sendInvoiceDigitalMail = false;
    @observable sendInvoicePhysicalMail = false;

    @observable createdAt = null;
    @observable updatedAt = null;
    @observable exportedAt = null;

    @observable portalEnabled = false;

    @observable portalUser = this.relation(User);
    @observable entity = this.relation(Entity);
    @observable group = this.relation(CustomerGroup);
    @observable contracts = this.relation(ContractStore);
    @observable contacts = this.relation(ContactStore);

    @computed get city() {
        if (this.invoicingCity) {
            return this.invoicingCity
        }
        return this.visitingCity
    }

    @computed get displayName() {
        // Filters are there to prevent empty strings from being joined
        let address = [this.visitingZipCode, this.visitingCity].filter(x=>!!x).join(', ');
        address = address ? `[${address}]` : address;

        return [this.customerNumber, this.name, address].filter(x=>!!x).join(' ');
    }

    @action
    saveCustomerLocation(locationId, companyName) {
        return this.api.post(`customer/${this.id}/store-customer-location/`, {
            locationId: locationId,
            companyName: companyName,

        }).then(() => {
            showSaveNotification()
        })
        .catch((err) => {
            const errorCode: string[] = parseBackendErrorCodes(err.response.data.errors);
            if (errorCode.includes('existing_location_with_same_address')) {
                showErrorNotification(t('administration:activity.customerLocation.addressBookDuplicateError.message'));
            } else {
                showErrorNotification(parseBackendErrorMessages(err.response.data.errors))
            }
        });
    }
}

export class CustomerStore<M extends Customer = Customer> extends Store<M> {
    Model = Customer;
    static backendResourceName = 'customer';
}
