import { Model, Store } from '@code-yellow/spider';
import { round } from 'lodash';
import { observable } from 'mobx';
import { Trip } from 'react-logistics-administration/src';
import InvoicelineType from 'react-logistics-finance/src/store/enums/InvoiceLineType';
import { Invoice } from 'react-logistics-finance/src/store/Invoice';
import { SelfBillItem } from 'react-logistics-finance/src/store/SelfBillItem';

export class InvoiceLine extends Model {
    static backendResourceName = 'invoice_line';
    static omitFields = ['totalAmount'];

    @observable id: number | null = null;
    @observable type?: InvoicelineType;
    @observable vat?: number = 0;
    @observable group = 0;
    @observable ordering = 0;
    @observable amount?: number = 0;
    @observable description? : string;

    @observable invoice = this.relation(Invoice);

    get totalAmount(): number {
        return round((this.amount ?? 0) + this.vatAmount, 0);
    }

    get vatAmount(): number {
        return round((this.amount ?? 0) * (this.vat ?? 0) / 100, 0);
    }

    @observable trip = this.relation(Trip);
    @observable selfBillItem = this.relation(SelfBillItem);
}

export class InvoiceLineStore<M extends InvoiceLine = InvoiceLine> extends Store<M> {
    Model = InvoiceLine;
    static backendResourceName = 'invoice_line';
}

export interface IInvoiceLineLinkedObject extends Model {
    getDescription(language?: string): string;
    getAmount(): number;
}
