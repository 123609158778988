import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import truck_icon from 'react-logistics-administration/src/image/activity-icons/truck-icon.png';
import mobile_position_icon from 'react-logistics-administration/src/image/activity-icons/mobile-position-icon.png';
import first_position from 'react-logistics-administration/src/image/activity-icons/first-position.png';
import last_position from 'react-logistics-administration/src/image/activity-icons/last-position.png';

export type IconConfig = {
    iconUrl: string;
    shadowUrl?: string;
    iconSize: [number, number];
    iconAnchor: [number, number];
}

const DefaultIconConfig: IconConfig = {
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [20, 30],
    iconAnchor: [10, 30],
};

// TODO: replace first/last icons with nicer icons
// for now, they are just ugly colored thingies
const FirstPositionIconConfig: IconConfig = {
    iconUrl: first_position,
    shadowUrl: iconShadow,
    iconSize: [20, 30],
    iconAnchor: [10, 30],
};
const LastPositionIconConfig: IconConfig = {
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [20, 30],
    iconAnchor: [10, 30],
};

const TruckIconConfig: IconConfig = {
    iconUrl: truck_icon,
    iconSize: [31, 40],
    iconAnchor: [15, 40],
};

const MobilePositionIconConfig: IconConfig = {
    iconUrl: mobile_position_icon,
    iconSize: [31, 40],
    iconAnchor: [15, 40],
};

export const DefaultIcon = L.icon(DefaultIconConfig);
export const LastPositionIcon = L.icon(LastPositionIconConfig);
export const FirstPositionIcon = L.icon(FirstPositionIconConfig);
export const TruckIcon = L.icon(TruckIconConfig);
export const MobilePositionIcon = L.icon(MobilePositionIconConfig);
