import { Model, Store, Casts, showSaveNotification, showErrorNotification } from '@code-yellow/spider';
import { computed, observable } from 'mobx';
import { TRAILER } from './enums/AssetType';
import { parseBackendErrorMessages } from 'helpers';
import { DateTime } from 'luxon';
import { Customer } from 'react-logistics-administration/src';


export class Trailer extends Model {
    static backendResourceName = 'trailer';
    static omitFields = ['lastKnownLocation', 'lastKnownLocationUpdatedAt', 'dossierNumber'];

    @observable id = null;
    @observable type = TRAILER;
    @observable licensePlate = '';
    @observable fleetNumber = '';
    @observable fleetCode = '';
    @observable remarks = '';
    @observable brand = '';
    @observable trailerType = '';
    @observable active = true;
    @observable apkExpiryDate = null;

    // Trailer types
    @observable taut = false;
    @observable frigo = false;
    @observable box = false;
    @observable lift = false;

    // @observable currentActivity = this.relation(Activity); virtual relation commented out, maybe will be used later
    @observable lastKnownLocation = null;
    @observable lastKnownLocationUpdatedAt: DateTime | null = null;
    @observable dossierNumber = null;
    @observable truckingCompany = this.relation(Customer);

    casts() {
        return {
            apkExpiryDate: Casts.date,
        }
    }

    @computed get identificator() {
        return this.fleetNumber;
    }

    @computed get editUrl() {
        return `/master-data/trailer/${this.id}/edit`;
    }
    @computed
    get label() {
        return `${this.fleetNumber}`;
    }

    @computed
    get fullLabel() {
        return `${this.fleetNumber} | ${this.licensePlate}`;
    }

    @computed
    get subLabel() {
        return `${this.licensePlate}`;
    }

    upcomingEvent(event) {
        const today = DateTime.local();
        return (event > today && event < today.plus({ months: 2 }))
    }

    setActive(value) {
        this.active = value;
        this.save().then(() => {
            showSaveNotification();
        }).catch((e) => {
            this.active = !value;
            const errorText = parseBackendErrorMessages(e.response.data.errors);
            showErrorNotification(errorText ?? e);
        });
    }
}

export class TrailerStore extends Store<Trailer> {
    Model = Trailer;
    static backendResourceName = 'trailer';
}
