import { Link, RightDivider, TargetCheckbox, ViewEdit, showSaveNotification } from '@code-yellow/spider';
import styled from 'styled-components';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, IReactionDisposer, observable, reaction } from 'mobx';
import { omit } from 'lodash';
import {
    Row,
    Col,
    Subheading,
} from 're-cy-cle';
import { SaveButton, CancelButton } from '@code-yellow/spider';
import { Form, Tab } from 'semantic-ui-react';
import { TargetTextInput, t, TargetSelect, TargetNumberInput, TargetTextArea } from '@code-yellow/spider';
import { ColorBox, Customer } from 'react-core-administration/src';
import { BaseRow } from 'component/Compact/BaseRow';
import { EntityStore } from 'react-core-administration/src/store/Entity';
import { CustomerGroup, CustomerGroupStore } from 'react-core-administration/src/store/CustomerGroup';
import { CustomerLocationStore } from 'react-logistics-administration/src/store/CustomerLocation';
import Toolbar from 'component/Toolbar';
import { Customer as LogisticsCustomer } from 'react-logistics-administration/src';
import CustomerInvoiceLanguage from 'react-core-administration/src/store/enums/CustomerInvoiceLanguage';
import { LedgerStore } from 'react-logistics-finance/src/store/Ledger';
import { TargetPhoneNumberInput } from 'react-core-administration/src/component/TargetPhoneNumberInput';
import { formatInputToUppercase, validateIbanInput, validateZipCodeInput, validateBicInput } from 'react-core-administration/src/helpers';
import { TargetCountrySelect } from 'react-core-administration/src/component/TargetCountrySelect';
import { getGlobalValue } from 'helpers';
import { TargetMoneyInput } from 'component/Target';

const SubheadingCustom = styled(Subheading)`
    &:nth-of-type(n + 2) {
        margin-top: 20px !important;
    }
    font-weight: 700;
    margin-bottom: 20px !important;
`;

const ColoredTargetMoneyInput = styled(TargetMoneyInput)`
    input {
        ${(props) => {
        if (props.value > props.limit) {
            return 'color: red !important;';
        } else {
            return 'color: black !important;';
        }
    }}
    }
`;

export const FlexibleTargetSelect = styled(TargetSelect)`
    > div {
        min-width: auto!important;
    }
`;

const CustomerPortalContainer = styled.div`
    width: 100%;
    background-color: var(--gray-300) !important;
    border-radius: .3rem;
    padding: 8px 10px 1px 10px;

    [data-test-form-label] {
        font-size: 12px!important;
    }

    .field {
        padding-left: 0.2em!important;
        padding-right: 0.2em!important;
    }
    margin-bottom: 5px;
`

const CustomerPortalContainerHeader = styled.span`
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 700;
`

const CustomTargetCheckbox = styled(TargetCheckbox)`
    display: flex;
    justify-content: space-between;
`

type CustomerEditProps = {
    customer: LogisticsCustomer,
    afterSave: () => null
}

/**
 * fetch global values for payment term, and set these for the customer.
 * Only used for newly created customers
 *
 * @param {LogisticsCustomer} customer a customer
 */
export async function setCustomerPaymentTermsAsGlobal(customer: LogisticsCustomer) {
    if (!customer.isNew) {
        return
    }
    const [globalPaymentTermCreditorValue, globalPaymentTermDebtorValue] = await Promise.all([getGlobalValue('payment_term_creditor'), getGlobalValue('payment_term_debtor')]);
    if (globalPaymentTermCreditorValue) {
        customer.setInput('paymentTermCreditor', globalPaymentTermCreditorValue);
    }
    if (globalPaymentTermDebtorValue) {
        customer.setInput('paymentTermDebtor', globalPaymentTermDebtorValue);
    }
}

@observer
export default class CustomerEdit extends Component<CustomerEditProps> {
    saveRelations = ['entity', 'group'];
    @observable entityStore = new EntityStore()
    @observable customerGroupStore = new CustomerGroupStore({
        params: {
            'limit': '100',
            order_by: 'group_number',
        },
    });
    @observable customerLocations = new CustomerLocationStore({ relations: ['location'] });
    @observable ledgerStore = new LedgerStore();
    entityReaction?: IReactionDisposer;

    async componentDidMount() {
        this.entityReaction = reaction(
            () => this.props.customer?.entity?.id,
            (id) => {
                this.ledgerStore.params['.entity'] = id ?? -1;
                this.ledgerStore.fetch();
            },
            { fireImmediately: true }
        )
        setCustomerPaymentTermsAsGlobal(this.props.customer)
    }

    componentWillUnmount() {
        this.entityReaction?.();
    }

    saveMethod = ({ createNew = false, saveAndBack = false }) => {
        const { afterSave, customer } = this.props;
        return customer
            .save({
                onlyChanges: true,
                relations: this.saveRelations,
                mapData: data => omit(data, 'locations'), // Never save `locations` directly.
            })
            .then(() => {
                const afterSaveProps: { goTo?: string, goBack?: boolean } = { goBack: false };
                if (saveAndBack) {
                    afterSaveProps.goTo = '/administration/customer/overview';
                    afterSaveProps.goBack = saveAndBack;
                }
                if (createNew) {
                    afterSaveProps.goTo = '/administration/customer/add';
                    afterSaveProps.goBack = createNew;
                    customer.clear();
                }
                afterSave(afterSaveProps)
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    @action
    save = ({ createNew = false, saveAndBack = false }) => {
        const { afterSave, customer } = this.props;

        // Unfortunely when inheriting from a model we cannot save models related to the base model because binder incorrectly recognises the relation
        // Thats why we are saving invoice lines in a separate call untill it will be fixed in binder
        return customer.save({
            onlyChanges: true,
            relations: this.saveRelations,
            mapData: data => omit(data, 'contacts'), // Never save `locations` directly.
        }).then(() => { // Remember to not add lines to the save relations there! We will save them separately
            const coreCustomer = new Customer({ id: customer.id }, { relations: ['contacts'] });

            // Store contacts in separate variable for later
            const contacts = [...customer.contacts.models];

            // Add lines to the base model
            contacts.forEach(contact => {
                const newContact = coreCustomer.contacts.add(contact.toJS());

                // Need to copy the tracked changes, otherwise `onlyChanges: true` will not see any changes.
                newContact.__changes = contact.__changes;
            })

            const promise =  coreCustomer.save({ onlyChanges: true, relations: ['contacts'] }).then((data) => {
                customer.contacts.models.clear();

                // We do save on base model so ids of the lines are not updated on the proper model. We need to map the ids manually
                customer.contacts.models.push(...coreCustomer.contacts.models);

                return data;
            });
            return promise;
        })
        .then(() => {
            const afterSaveProps: {goTo?: string, goBack?: boolean} = { goBack: false };
            if (saveAndBack){
                afterSaveProps.goTo = '/administration/customer/overview';
                afterSaveProps.goBack = saveAndBack;
            }
            if(createNew){
                afterSaveProps.goTo = '/administration/customer/add';
                afterSaveProps.goBack = createNew;
                customer.clear();
            }
            afterSave(afterSaveProps)
        })
        .then(showSaveNotification)
        .then(afterSave)
    }


    cancel = action(() => {
        const { customer } = this.props;
        return customer.clear();
    });

    _renderTitle(customer: LogisticsCustomer) {
        if (customer?.isNew) {
            return t('administration:customer.create.title');
        }

        let city: string;
        if (customer.invoicingCity && customer.invoicingCity !== '') {
            city = customer.invoicingCity;
        } else if (customer.visitingCity) {
            city = customer.visitingCity;
        } else {
            city = '-';
        }

        // Looks like: `Edit customer 001234 De Visser Staal Recycling | Udenhout`
        return t('administration:customer.edit.title', {
            name: `${customer.customerNumber} ${customer.name} | ${city}`
        });
    }

    _renderBasicInfoFields(customer: LogisticsCustomer) {
        // Not sure why but outside of the class the translations are not working, ¯\_(ツ)_/¯
        const CUSTOMER_INVOICE_LANGUAGE_OPTIONS = Object.values(CustomerInvoiceLanguage).map((language) => ({
            text: t(`administration:customer.field.invoiceLanguage.option.${language}`),
            value: language,
        }));

        return <>
            <SubheadingCustom>
                {t('administration:customer.edit.basicInfoTitle')}
            </SubheadingCustom>

            <TargetTextInput
                required
                target={customer}
                name='name'
                label={t('administration:customer.field.name.label')}
                value={customer.name}
            />
            <TargetTextArea autoHeight
                target={customer}
                name='additionalNameField'
                label={t('administration:customer.field.additionalNameField.label')}
                info={t('administration:customer.field.additionalNameField.info')}
                value={customer.additionalNameField}
            />
            <TargetTextArea autoHeight
                target={customer}
                name='internalNotes'
                label={t('administration:customer.field.internalNotes.label')}
                value={customer.internalNotes}
            />
            <TargetSelect
                search
                remote
                required
                clearable
                searchKey=".name:icontains"
                target={customer}
                label={t('administration:customer.field.customerGroup.label')}
                name="group"
                store={this.customerGroupStore}
                toOption={(customerGroup: CustomerGroup) => ({
                    value: customerGroup.id,
                    text: <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><ColorBox color={customerGroup.color} />{customerGroup.groupNumber} {customerGroup.name}</span>,
                })}
            />
            <TargetSelect search remote required
                searchKey=".name:icontains"
                target={customer}
                label={t('administration:customer.field.entity.label')}
                name="entity"
                error={customer.backendValidationErrors.entity}
                store={this.entityStore}
                afterChange={()=>customer.setInput('defaultLedger', null)}
                toOption={entity => ({
                    value: entity.id,
                    text: entity.name,
                })}
            />
            <TargetPhoneNumberInput
                target={customer}
                name='phone'
                label={t('administration:customer.field.phone.label')}
                placeholder='+31 6 12345678'
                error={customer.backendValidationErrors.phone}
                value={customer.phone}
            />
            <TargetTextInput
                target={customer}
                name='customerNumber'
                label={t('administration:customer.field.customerNumber.label')}
            />
            <TargetTextInput
                target={customer}
                label={t('administration:customer.field.iban.label')}
                error={customer.backendValidationErrors.iban}
                name='iban'
                onKeyPress={validateIbanInput}
                onBlur={(event: Event) => formatInputToUppercase(event, customer.iban, customer, 'iban')}
            />
            <TargetTextInput
                target={customer}
                label={t('administration:customer.field.bic.label')}
                name='bic'
                onKeyPress={validateBicInput}
                onBlur={(event: Event) => formatInputToUppercase(event, customer.bic, customer, 'bic')}
            />
            <TargetTextInput
                target={customer}
                label={t('administration:customer.field.chamberOfCommerce.label')}
                error={
                    customer.backendValidationErrors
                        .chamberOfCommerce
                }
                name='chamberOfCommerce'
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
            />
            <TargetSelect
                target={customer}
                label={t('administration:customer.field.invoiceLanguage.label')}
                name="invoiceLanguage"
                options={CUSTOMER_INVOICE_LANGUAGE_OPTIONS}
            />
            <TargetNumberInput
                target={customer}
                label={t('administration:customer.field.kmToleranceThreshold.label')}
                error={customer.backendValidationErrors.kmToleranceThreshold}
                suffix=" %"
                name='kmToleranceThreshold'
            />
            <Form.Group widths="equal">
                <TargetTextInput
                    required
                    target={customer}
                    label={t('administration:customer.field.vatNumber.label')}
                    error={customer.backendValidationErrors.vatNumber}
                    name='vatNumber'
                />
                <FlexibleTargetSelect
                    required
                    disabled={customer.entity?.id == null}
                    target={customer}
                    name='defaultLedger'
                    label={t('administration:customer.field.vatCode.label')}
                    store={this.ledgerStore}
                    toOption={ledger => ({
                        value: ledger.id,
                        text: `${ledger.number} ${ledger.description}`,
                    })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <TargetTextInput
                    target={customer}
                    label={t('administration:customer.field.paymentTermCreditor.label')}
                    error={customer.backendValidationErrors.paymentTermCreditor}
                    name='paymentTermCreditor'
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                />
                <TargetTextInput
                    target={customer}
                    label={t('administration:customer.field.paymentTermDebtor.label')}
                    error={customer.backendValidationErrors.paymentTermDebtor}
                    name='paymentTermDebtor'
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                />
            </Form.Group>
            <BaseRow>
                <Col xs={4}>
                    <TargetMoneyInput
                        fluid
                        toFixed
                        label={t('administration:customer.field.creditLimitAmount.label')}
                        target={customer}
                        name='creditLimitAmount'
                        placeholder='€0.000'
                    />
                </Col>
                <Col xs={4}>
                    <ColoredTargetMoneyInput
                        fluid
                        toFixed
                        disabled
                        label={t(
                            'administration:customer.field.creditLimitUsed.label'
                        )}
                        value={customer.creditLimitUsed}
                        limit={customer.creditLimitAmount}
                        placeholder='€0.000'
                    />
                </Col>
            </BaseRow>
        </>;
    }

    _renderVisitingAddressFields(customer) {
        return <>
            <SubheadingCustom>
                {t('administration:customer.edit.visitingLocationTitle')}
            </SubheadingCustom>
            <BaseRow>
                <Col xs={8}>
                    <TargetTextInput
                        target={customer}
                        label={t(
                            'administration:customer.field.visitingAddress.label'
                        )}
                        error={
                            customer.backendValidationErrors
                                .visitingAddress
                        }
                        name='visitingAddress'
                        value={customer.visitingAddress}
                    />
                </Col>
                {/* <Col xs={4}>
                    <TargetTextInput
                        target={customer}
                        label={t(
                            'administration:customer.field.visitingNumber.label'
                        )}
                        error={
                            customer.backendValidationErrors
                                .visitingNumber
                        }
                        name='visitingNumber'
                        value={customer.visitingNumber}
                    />
                </Col> */}
                <Col xs={4}>
                    <TargetTextInput
                        target={customer}
                        name='visitingZipCode'
                        label={t('administration:customer.field.visitingZipCode.label')}
                        value={customer.visitingZipCode}
                        onKeyPress={validateZipCodeInput}
                        onBlur={(event: Event) => formatInputToUppercase(event, customer.visitingZipCode, customer, 'visitingZipCode')}
                    />
                </Col>
            </BaseRow>
            <BaseRow>
                <Col xs={6}>
                    <TargetTextInput
                        target={customer}
                        name='visitingCity'
                        label={t('administration:customer.field.visitingCity.label')}
                    />
                </Col>
                <Col xs={6}>
                    <TargetCountrySelect
                        fluid
                        target={customer}
                        name='visitingCountry'
                        label={t('administration:customer.field.visitingCountry.label')}
                    />
                </Col>
            </BaseRow>
        </>
    }

    _renderInvoicingAddressFields(customer) {
        return <>
            <SubheadingCustom>
                {t('administration:customer.edit.invoicingLocationTitle')}
            </SubheadingCustom>
            <BaseRow>
                <Col xs={8}>
                    <TargetTextInput
                        target={customer}
                        label={t(
                            'administration:customer.field.invoicingAddress.label'
                        )}
                        error={
                            customer.backendValidationErrors
                                .invoicingAddress
                        }
                        name='invoicingAddress'
                        value={customer.invoicingAddress}
                    />
                </Col>
                {/* <Col xs={4}>
                    <TargetTextInput
                        target={customer}
                        label={t(
                            'administration:customer.field.invoicingNumber.label'
                        )}
                        error={
                            customer.backendValidationErrors
                                .invoicingNumber
                        }
                        name='invoicingNumber'
                        value={customer.invoicingNumber}
                    />
                </Col> */}
                <Col xs={4}>
                    <TargetTextInput
                        model={customer}
                        target={customer}
                        visitingCity
                        name='invoicingZipCode'
                        label={t('administration:customer.field.invoicingZipCode.label')}
                        onKeyPress={validateZipCodeInput}
                    />
                </Col>
            </BaseRow>
            <BaseRow>
                <Col xs={6}>
                    <TargetTextInput
                        model={customer}
                        target={customer}
                        name='invoicingCity'
                        label={t('administration:customer.field.invoicingCity.label')}
                    />
                </Col>
                <Col xs={6}>
                    <TargetCountrySelect
                        fluid
                        target={customer}
                        name='invoicingCountry'
                        label={t('administration:customer.field.invoicingCountry.label')}
                    />
                </Col>
            </BaseRow>
        </>;
    }

    _renderCustomerPortalFields(customer) {
        return (
            <>
                <CustomerPortalContainerHeader>
                    {t('administration:customer.edit.customerPortalTitle')}
                </CustomerPortalContainerHeader>
                <CustomerPortalContainer>
                    <CustomTargetCheckbox
                        data-test-portal-enable
                        toggle
                        target={customer}
                        label={t(
                            'administration:customer.field.portalEnabled.label'
                        )}
                        name="portalEnabled"
                    />
                    {customer.portalUser.id &&
                        <div style={{ fontSize: 12, paddingLeft: 3 }} data-test-portal-user-email>
                            <b>{t('administration:customer.field.portalUser.label')}:</b> <br />
                            {customer.portalUser.email}
                            (<Link to={`/account/user/${customer.portalUser.id}/edit`}>{t('tooltips.edit')}</Link>)
                        </div>
                    }
                </CustomerPortalContainer>
            </>
        )
    }

    _renderSidebarFields(customer) {
        return <>
            {this._renderBasicInfoFields(customer)}
            {this._renderCustomerPortalFields(customer)}
            {this._renderVisitingAddressFields(customer)}
            {this._renderInvoicingAddressFields(customer)}
        </>;
    }

    _renderSidebarContent(customer) {

        return (
            <Form>
                <Row>
                    <Col xs={12}>
                        {this._renderSidebarFields(customer)}

                    </Col>
                </Row>
            </Form>
        );
    }

    _renderMainContent(customer) {
        return (
            <Tab
                cache='false'
                menu={{ secondary: true, pointing: true }}
                panes={this._getPanes(customer)}
                data-test-panes
            />
        );
    }

    _renderToolbarContent = (customer) => {
        return (
            <>
                <RightDivider />
                <CancelButton
                    compact
                    onClick={() => {
                        if (window.confirm(t('form.clearConfirmation'))) {
                            this.cancel();
                        }
                    }}
                    content={t('masterData:form.cancel')}
                    loading={customer.isLoading}
                />
                <SaveButton primary compact
                    onClick={() => this.save({ saveAndBack: true })}
                    content={t('masterData:form.saveAndBack')}
                    loading={customer.isLoading}
                />
                <SaveButton primary compact
                    onClick={() => this.save({ createNew: true })}
                    content={t('masterData:form.saveCreateEmpty')}
                    loading={customer.isLoading}
                />
                <SaveButton
                    primary
                    compact
                    data-test-save-same-customer-button
                    loading={customer.isLoading}
                    onClick={this.save}
                />
            </>
        );
    };

    render() {
        const { customer } = this.props;

        return (
            <>
                <ViewEdit
                    renderTitle={() => this._renderTitle(customer)}
                    renderSidebarContent={() =>
                        this._renderSidebarContent(customer)
                    }
                    renderMainContent={() => this._renderMainContent(customer)}
                    renderToolbarContent={() =>
                        this._renderToolbarContent(customer)
                    }
                    Toolbar={Toolbar}
                ></ViewEdit>
            </>
        );
    }
}
